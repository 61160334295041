/* global $, moment */
/* eslint-disable no-console */

(function() {
    const revealObjects = document.querySelectorAll('*[data-timereveal]');
    const concealObjects = document.querySelectorAll('*[data-timeconceal]');
    const animationSpeed = 500;

    const revealTimer = (animate = true) => {
        revealObjects.forEach(obj => {
            const revealTime = obj.dataset.timereveal;
            if (moment() >= moment(revealTime)) {
                if (animate) {
                    $(obj)
                        .delay(animationSpeed)
                        .show(
                            'blind',
                            {
                                direction: 'vertical',
                            },
                            animationSpeed
                        );
                } else {
                    $(obj).show();
                }
            }
        });

        concealObjects.forEach(obj => {
            const concealTime = obj.dataset.timeconceal;
            if (moment() >= moment(concealTime)) {
                if (animate) {
                    $(obj).hide(
                        'blind',
                        {
                            direction: 'vertical',
                        },
                        animationSpeed
                    );
                } else {
                    $(obj).hide();
                }
            }
        });
    };

    revealObjects.forEach(obj => {
        $(obj).hide();
    });

    revealTimer(false);
    const reveal = setInterval(revealTimer, 1000);
})();
